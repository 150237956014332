<template>
  <div>
    <SkeletonCrudGalleryPhotos v-if="isDeletingPhotos || isSavingPhotos"/>
    <div v-else>

      <div class="upload__box mb-2">
        <div class="upload__btn-box" v-if="localImages.length <= 5 ">
          <label class="upload__btn">
          <p> {{ $t('Upload photos') }} </p>
            <form ref="formFiles">
              <input type="file" accept=".png, .jpg, .jpeg"  class="upload__inputfile" @change="uploadPhotos"  multiple>
            </form>
          </label>

          <b-button variant="warning" v-if="localImages.length" @click="clearPhotosPreview" :disabled="isSavingPhotos" class="upload__btn ml-1 half-padding">
            <font-awesome-icon icon="fa-solid fa-trash" class="icon-edit-avatar" v-if="!isSavingPhotos"/> {{ $t('Reset') }}
          </b-button>
        </div>

        <hr>

        <div class="upload__img-wrap" v-if="localImages.length">
          <div class='upload__img-box' v-for="(img, index) in localImages" :key="index">
            <b-img :src="img" height="125" width="125" fluid alt="Responsive image" :style="newPhotoProfile(img)" @click="showImage" >
              <div class='upload__img-close' @click="removeImgPreview(index)"></div>
            </b-img>
          </div>
        </div>
      </div>

      <div class="upload__box mb-2" v-if="requestImages.length">
        <b-card-text v-if="requestImages && requestImages.length"><h4> {{ $t('Delete photos') }}</h4></b-card-text>
        <div class="upload__img-wrap" v-if="requestImages && requestImages.length">
          <div class='upload__img-box' v-for="(img) in requestImages" :key="img.id">
            <div :style="imgGallery(img)" class='img-bg' @click="deletePhoto(img)">
              <div class='upload__img-close'>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { showAlertMessage, getLocalFilePath } from '@/helpers/helpers'
import { utils } from "@/modules/owners/mixins/utils"
// Skeletons
import SkeletonCrudGalleryPhotos from '@/modules/auth/components/skeletons/SkeletonCrudGalleryPhotos'

export default {
  mixins: [ utils ],
  components: {
    SkeletonCrudGalleryPhotos
  },
  props: {
    requestImages: {
      type: Array,
      default: [],
      required: true
    },
  },
  data() {
    return {
      localImages: [],
      imgs: [],
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      isSavingPhotos: false,
      isDeletingPhotos: false,
    }
  },
  computed: {
    ...mapState('auth',['user', 'myContracts']),
    // sourceFile(){
    //   if (typeof this.requestImages?.images != 'string') return getLocalFilePath(this.localImages)
    //   else return this.imgUrl + this.requestImages?.images
    // },
  },
  methods: {
    ...mapActions('owners', ['pushActionsService']),

    async uploadPhotos() {
      const title = this.$t('Limit 5 photos')
      const msgLimit = this.$t('Only limit 5 photos')

      const files = event.target.files
      if( !files || this.localImages.length > 4 ){
        if(this.localImages.length > 4 ){
          showAlertMessage(title, 'XIcon', msgLimit, 'danger', 4000, 'bottom-right')
        }
        return
      }
      this.imgs = files
      this.imgs.forEach( (f, index) => {
        this.localImages.push(getLocalFilePath(f))
      })
      if( this.imgs.length > 5){
        showAlertMessage(title, 'XIcon', msgLimit, 'danger', 4000, 'bottom-right')
      }
      await this.pushData('onChange', 'uploadPhotoGallery', 'uploadPhotoGallery', this.$route.name) // push a back del registro
      // this.$refs.formFiles.reset()
    },
    newPhotoProfile(img){
      return img ? `background-image: url('${img}');` : ''
    },
    imgGallery(img){
      return img ? `background-image: url('${this.imgUrl + img.urlmomentpicture }');` : ''
    },
    async removeImgPreview(indexImage){
      this.localImages.splice(indexImage, 1)
      await this.pushData('onClick', 'deleteTemporalPhotoGallery', 'deleteTemporalPhotoGallery', this.$route.name) // push a back del registro
    },
    async clearPhotosPreview(){
      this.localImages = []
      await this.pushData('onClick', 'resetForm', 'resetForm', this.$route.name, 'photos gallery') // push a back del registro
    },
    async savePhotos(){
      // validar si es admin desde mixin en utils
      if( this.isAdmin ){
        return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
      }

      this.isSavingPhotos = true
      const payloadPhotos = []
      this.imgs.forEach( photo => {
        payloadPhotos.push({idOwner: this.user.idOwner, photo })
      })
      const responses = await this.savePhotosGallery(payloadPhotos)
      const  { status } = responses[responses.length - 1][0] //obtengo la ultima respuesta
      if( status ){
        const title = this.$t(`Photos saved`)
        const mgs = this.$t(`The photos have been saved`)
        showAlertMessage(title, 'XIcon', mgs, 'success', 4000, 'bottom-right')
        const data = { idOwner: this.user.idOwner }
        const urlmomentpicture  = await this.getPhotosGalleryUser(data)
        let pictures = []
        pictures = ( urlmomentpicture && urlmomentpicture.length ) ? urlmomentpicture : []
        this.setGalleryPhotosUser(pictures)
      }
      this.isSavingPhotos = false
      this.localImages = []
      this.imgs = []
      await this.pushData('onClick', 'savePhotosGallery', 'savePhotosGallery', this.$route.name,) // push a back del registro

      // location.reload()
    },
    async deletePhoto(img){
      // validar si es admin desde mixin en utils
      if( this.isAdmin ){
        return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
      }

      const isConfirmedDelete = await this.confirmDelete()
      if(isConfirmedDelete){
        this.isDeletingPhotos = true
        const payload = { idOwner: this.user.idOwner, idGallery: img.id }
        const { status } = await this.deletePhotoOwner(payload)
        if( status ){
          const title = this.$t(`Photo deleted`)
          const mgs = this.$t(`Your photo has been removed`)
          showAlertMessage(title, 'XIcon', mgs, 'success', 4000, 'bottom-right')
          const data = { idOwner: this.user.idOwner }
          const urlmomentpicture  = await this.getPhotosGalleryUser(data)
          let pictures = []
          pictures = ( urlmomentpicture && urlmomentpicture.length ) ? urlmomentpicture : []
          this.setGalleryPhotosUser(pictures)
        }
      }
      await this.pushData('onClick', 'deletePhotoGallery', 'deletePhotoGallery', this.$route.name,) // push a back del registr
      this.isDeletingPhotos = false
      // location.reload()
    },
    async confirmDelete() {
      const { isConfirmed } = await this.$swal.fire({
        title: `${ this.$t('Are you sure') }`,
        text: `${ this.$t('Are you sure to delete the photo') }`,
        showDenyButton: true,
        confirmButtonText: `${ this.$t('Yes, sure') }`,
        denyButtonText: `${ this.$t('Cancel') }`,
        customClass: {
          confirmButton: 'mr-1',
        }
      });
      return isConfirmed;
    },
  }
}
</script>

<style scoped>

p {
  margin: 0;
}
.upload__box {
	padding:0 20px 0 20px;
}
.upload__inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
 .upload__btn {
	 display: inline-block;
	 font-weight: 600;
	 color: #fff;
	 text-align: center;
	 min-width: 116px;
	 padding: 5px;
	 transition: all 0.3s ease;
	 cursor: pointer;
	 border: 2px solid;
	 background-color: #4045ba;
	 border-color: #4045ba;
	 border-radius: 10px;
	 line-height: 26px;
	 font-size: 14px;
}
 .upload__btn:hover {
	 background-color: unset;
	 color: #4045ba;
	 transition: all 0.3s ease;
}
 .upload__btn-box {
	 margin-bottom: 10px;
}
 .upload__img-wrap {
	 display: flex;
	 flex-wrap: wrap;
	 margin: 0 -10px;
}
 .upload__img-box {
	 width: 200px;
	 padding: 0 10px;
	 margin-bottom: 12px;
}
 .upload__img-close {
	 width: 24px;
	 height: 24px;
	 border-radius: 50%;
	 background-color: rgba(179, 11, 11, 0.5);
	 position: absolute;
	 top: 10px;
	 right: 10px;
	 text-align: center;
	 line-height: 24px;
	 z-index: 1;
	 cursor: pointer;
}
 .upload__img-close:after {
	 content: '\2716';
	 font-size: 14px;
	 color: white;
}
 .img-bg {
	 background-repeat: no-repeat;
	 background-position: center;
	 background-size: cover;
	 position: relative;
	 padding-bottom: 100%;
}

.gallery-image:hover {
	transform: scale(1.10);
}

.half-padding{
  padding: 0.2rem 1rem;
}

</style>