<template>
  <b-overlay :show="isLoadingControlAccessData" rounded class="">
    <ValidationObserver
      ref="formRequestMaintenance"
      tag="form"
    >
      <b-form @submit.prevent="sendDataForm">

        <!-- Tipo de Accesso -->
        <div class="border rounded p-1 mb-1">
          <h5>{{$t('Request data')}}</h5>
          <b-row>
         
            <b-col md="12">
              <ValidationProvider :name="$t('Reason')" rules="required|max:100">
                <b-form-group slot-scope="{ errors }">
                  <label> {{ $t('Reason')}}* </label>
                  <b-form-input
                    :placeholder="$t('Reason')"
                    v-model="requestData.reason"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="12" class="mt-1">
              <validation-provider :name="$t('description')" rules="required|max:500">
                <b-form-group slot-scope="{ errors }">
                  <label> {{ $t('description')}}* </label>
                  <b-form-textarea
                    v-model="requestData.description"
                    :placeholder="$t('Write description')"
                    rows="2"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <h6 class="mt-2">{{ $t('Maintenance Dates')}}</h6>
          <b-row>
            <b-col md="3">
              <ValidationProvider :name="$t('Initial date')" rules="required">
                <b-form-group slot-scope="{ errors }">
                  <label> {{ $t('Initial date')}}* </label>
                  <flat-pickr
                    :config="dateInConfig"
                    :class="`form-control white-background ${errors.length > 0 ? 'is-invalid' : ''}`"
                    v-model="requestData.startDate"
                    :placeholder="$t('pick date')"
                    :state="errors.length > 0 ? false : null"
                  />
                    <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <ValidationProvider :name="$t('End date')" rules="required">
                <b-form-group slot-scope="{ errors }">
                  <label> {{ $t('End date')}}* </label>
                  <flat-pickr
                    :config="dateOutConfig"
                    :class="`form-control white-background ${errors.length > 0 ? 'is-invalid' : ''}`"
                    v-model="requestData.endDate"
                    :placeholder="$t('pick date')"
                    :state="errors.length > 0 ? false : null"
                  />
                    <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

          </b-row>
        </div>

        <!-- Datos del proveedor -->
        <div class="border rounded p-1 mb-1">
          <h5>{{$t('Provider data')}}</h5>
          <b-row>
            <b-col cols="3">
              <ValidationProvider :name="$t('Provider type')" rules="required">
                <b-form-group slot-scope="{ errors }">
                  <label> {{ $t('Provider type')}}* </label>
                  <b-form-select
                    v-model="requestData.provider"
                    :state="errors.length > 0 ? false : null"
                  >
                    <option :value="null" disabled>{{$t('Select an option')}}</option>
                    <option
                      v-for="(reason, index) in providerType"
                      :key="index"
                      :value="reason.id"
                    >
                      {{ reason.name }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="">
              <ValidationProvider :name="$t('name')" rules="required">
                <b-form-group slot-scope="{ errors }">
                  <label> {{ $t('name')}}* </label>
                  <b-form-input
                    :placeholder="$t('name')"
                    v-model="requestData.providerName"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3">
              <ValidationProvider :name="$t('Phone')" rules="required|max:15">
                <b-form-group slot-scope="{ errors }">
                  <label> {{ $t('Phone')}}* </label>
                  <b-form-input
                    :maxlength="10"
                    type="number"
                    :placeholder="$t('Phone')"
                    v-model="requestData.providerPhone"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col cols="">
              <ValidationProvider :name="$t('Email')" rules="required|email">
                <b-form-group slot-scope="{ errors }">
                  <label> {{ $t('Email')}}* </label>
                  <b-form-input
                    :placeholder="$t('Email')"
                    v-model="requestData.providerEmail"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </div>

        <!-- Files -->
        <div class="border rounded p-1 mb-1">
          <h5>{{$t('Docs')}}</h5>
          <b-row>
            <b-col md="6">
              <ValidationProvider :rules="currentFiles.length > 0 ? 'size:4000' : 'required|size:4000'" :name="$t('Docs')">
                <b-form-group slot-scope="{ errors }">
                  <label>{{$t('max files', {max: 5})}} {{$t('max size', {len: '4MB'})}}</label>
                  <b-form-file
                    v-model="files"
                    :placeholder="currentFiles.length ? `${currentFiles.length} ${$t('files selected')}` : $t('select files')"
                    :drop-placeholder="$t('Drop file here...')"
                    :state="errors.length > 0 ? false : null"
                    :browse-text="`${$t('find')} ${$t('Docs')}`"
                    :file-name-formatter="formatFilesNames"
                    accept=".doc, .docx, .pdf, .xlsx, .xls"
                    @input="setFile(files)"
                    :disabled="isLoadingControlAccessData || isSavingControlAccessData || !canUpdateThisRequest"
                    multiple
                  />
                  <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="12" v-if="currentFiles.length > 0">
              <div class="upload__img-wrap">
                <div v-for="(file, index) in currentFiles" :key="file.name" class="upload__img-box text-center">
                  <div class='upload__file-close' @click="removeFile(index)" v-if="canUpdateThisRequest"></div>
                  <span>
                    <a
                      :href="getFileURL(file)"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <feather-icon icon="FileTextIcon" size="4x"/> <br>
                      <label>{{file.name}}</label>
                    </a>
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- Images -->
        <div class="border rounded p-1">
          <h5>{{$t('Images')}}</h5>
          <b-row>
            <b-col md="6">
              <ValidationProvider rules="size:4000" :name="$t('Images')">
                <b-form-group slot-scope="{ errors }">
                  <label>{{$t('max files', {max: 5})}} {{$t('max size', {len: '4MB'})}}</label>
                  <b-form-file
                    v-model="images"
                    :placeholder="currentImages.length ? `${currentImages.length} ${$t('images selected')}` : $t('select images')"
                    :drop-placeholder="$t('Drop file here...')"
                    :state="errors.length > 0 ? false : null"
                    :browse-text="`${$t('find')} ${$t('Images')}`"
                    :file-name-formatter="formatImagesNames"
                    accept="image/*"
                    @input="setImg(images)"
                    :disabled="isLoadingControlAccessData || isSavingControlAccessData || !canUpdateThisRequest"
                    multiple
                  />
                  <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="12" v-if="currentImages.length > 0">
              <div class="upload__img-wrap">
                <div v-for="(img, index) in currentImages" :key="img.name" class="upload__img-box">
                  <!-- <b-img :src="imgUrl + img.image" fluid alt="Responsive image" class="img-bg mt-1" ></b-img> -->
                  <div :style="imgGallery(img)" class='img-bg'>
                    <div class='upload__img-close' @click="removeImgPreview(index)" v-if="canUpdateThisRequest"></div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-row v-if="canUpdateThisRequest">
          <b-col>
            <b-card>
              <b-card-text class="text-center">
                <!-- {{$t('control access disclaimer')}} -->
              </b-card-text>
            </b-card>
          </b-col>

          <b-col md="3" class="mt-2">
            <ValidationProvider :name="$t('terms and use')" rules="required">
              <b-form-group>
                <b-form-checkbox
                  v-model="termsStatus"
                  :class="`float-right ${!termsStatus && evaluated ? 'is-invalid' : ''}`"
                  :value="true"
                  :unchecked-value="false"
                > {{$t('I accept')}}
                  <b-link :to="{name: 'termsConditions', params: { term: 22, lang: user.languageId } }" target="_blank" class="font-weight-bold link-text"> {{ $t('terms and use') }}  </b-link> *
                </b-form-checkbox>
                <b-form-invalid-feedback>
                  <span v-if="!termsStatus"> {{ $t('you must accept') }} {{ $t('terms and use') }} </span>
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="2">
            <b-button
              variant="primary"
              :disabled="isSavingControlAccessData"
              class="float-right mt-2"
              type="submit"
            > <b-spinner v-if="isSavingControlAccessData" small/>
              <feather-icon icon="SaveIcon" size="16" v-else/> {{$t('Save')}} 
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </ValidationObserver>
  </b-overlay>
</template>

<script>
import { mapState } from "vuex";
import { currentDate } from '@/helpers/market'
import { showAlertMessage, getLocalFilePath, filterFilesBySize } from '@/helpers/helpers'
import * as moment from "moment"
import { utils } from "@/modules/owners/mixins/utils"
import flatPickr from "vue-flatpickr-component"
import RequestPhotos from '@/modules/myProperty/components/RequestPhotos'

export default {
  mixins: [utils],
  async created(){
    this.currentFiles = structuredClone(this.requestData.files)
    this.currentImages = structuredClone(this.requestData.images)
    const maxGuestsAllowed = await this.getParameters(10)
    const maxDaysAllowed = await this.getParameters(11)
  },
  mounted(){
  },
  components: {
    flatPickr,
    RequestPhotos,
  },
  props: {
    requestData: {
      type: Object,
      default: null,
      required: true
    },
    originalAccessData: {
      type: Object,
      default: null,
      required: false
    },
    isLoadingControlAccessData: {
      type: Boolean,
      default: false,
      required: false
    },
    isSavingControlAccessData: {
      type: Boolean,
      default: false,
      required: true
    },
  },
  data() {
    return {
      today: currentDate(),
      guestsDataFields: [
        { key: 'name', label: this.$t('name'), },
        { key: 'lastname', label: this.$t('lastname'), },
        { key: 'email', label: this.$t('Email'), },
        { key: 'type', label: this.$t('guestType'), formatter: value=> {return this.typeGuests?.find(guest=> guest.value == value)?.text || value}},
        { key: 'actions', label: this.$t('Actions'), },
      ],
      providerType: [
        {id: "1", name: this.$t('External')},
        {id: "2", name: 'The Fives'}
      ],
      termsStatus:false,
      files: [],
      currentFiles: [],
      images: [],
      currentImages: [],
      evaluated: false,
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      dateInConfig: {
        minDate: moment().add(1, 'days').format("YYYY-MM-DD")
      }
    }
  },
  computed:{
    ...mapState('auth',['user', 'myContracts']),
    dateOutConfig(){
      return {
        minDate: this.requestData.startDate,
      }
    },
    maxDateToShow(){
      const maxDate = moment(this.requestData.dateIn).add(parseInt(this.maxDays), 'days').format("YYYY-MM-DD")
      return maxDate
    },
    isOutOfPool(){
      return this.myContracts?.contractSelected?.membershipcode === 'CLASSIC'
    },
    canUpdateThisRequest(){
      return this.requestData.status != 2 && this.requestData.status != 3
    }
  },
  methods:{
    async validateForm(){
      this.evaluated = false
      let formIsValid = await this.$refs.formRequestMaintenance.validate()
      if (!this.termsStatus) {
        showAlertMessage(this.$t('Cannot save register'), 'InfoIcon', `${this.$t('you must accept')} ${this.$t('terms and use')}`, 'warning', 4000, 'bottom-right')
        formIsValid = false
      }
      this.evaluated = true
      return formIsValid
    },
    async sendDataForm(){
      const isValid = await this.validateForm()
      if (isValid) {
        this.requestData.files = structuredClone(this.currentFiles)
        this.requestData.images = structuredClone(this.currentImages)
        this.$emit('save-register', this.requestData)
        this.evaluated = false
      }
    },
    setTime(payload){
      if (payload.type == 'timeIn') this.requestData.timeIn = payload.finalTime
      if (payload.type == 'timeOut') this.requestData.timeOut = payload.finalTime
    },
    getImgURL(img){
      return !!img?.id ? this.imgUrl + img.image : getLocalFilePath(img)
    },
    getFileURL(file){
      return !!file?.id ? this.imgUrl + file.file : getLocalFilePath(file)
    },
    imgGallery(img){
      return img ? `background-image: url('${this.getImgURL(img)}');` : ''
    },
    async removeImgPreview(indexImage){
      const newImages = this.currentImages.filter((img, index) => index != indexImage)
      this.currentImages = newImages
      // await this.pushData('onClick', 'deleteTemporalPhotoGallery', 'deleteTemporalPhotoGallery', this.$route.name) // push a back del registro
    },
    removeFile(indexFile){
      const newFiles = this.currentFiles.filter((img, index) => index != indexFile)
      this.currentFiles = newFiles
    },
    setFile(files){
      if (this.files?.length > 5 || this.currentFiles?.length > 5 || (this.files?.length + this.currentFiles?.length) > 5){
        this.files = structuredClone(this.requestData.files)
        return showAlertMessage( this.$t('max reached'), 'InfoIcon', this.$t('max limit', {amount: 5}), 'warning', 4000, 'bottom-right')
      } else {
        const filteredBySize = filterFilesBySize(this.files, 4194304)
        if (!filteredBySize) return false
        this.currentFiles = structuredClone(this.currentFiles.concat(this.files))
      }
    },
    setImg(imgs){
      if (this.images?.length > 5 || this.currentImages?.length > 5 || (this.currentImages?.length + this.images?.length) > 5){
        this.images = structuredClone(this.requestData.images)
        return showAlertMessage( this.$t('max reached'), 'InfoIcon', this.$t('max limit', {amount: 5}), 'warning', 4000, 'bottom-right')
      } else {
        const filteredBySize = filterFilesBySize(this.images, 4194304)
        if (!filteredBySize) return false
        this.currentImages = structuredClone(this.currentImages.concat(this.images))
      }
    },
    formatFilesNames(files){
      return `${this.currentFiles.length || 'No'} files selected`
    },
    formatImagesNames(images){
      return `${this.currentImages.length || 'No'} images selected`
    }

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.white-background{
  background-color: white !important;
}
.upload__img-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.upload__img-box {
  width: 10rem;
  padding: 0 10px;
  margin-bottom: 1rem;
}
.img-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-bottom: 100%;
}
.upload__file-close {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: rgba(179, 11, 11, 0.5);
  position: absolute;
  top: 3px;
  right: 1px;
  text-align: center;
  line-height: 18px;
  z-index: 1;
  cursor: pointer;
}
.upload__img-close {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: rgba(179, 11, 11, 0.5);
  position: absolute;
  top: -7px;
  right: -7px;
  text-align: center;
  line-height: 18px;
  z-index: 1;
  cursor: pointer;
}
.upload__img-close:after {
  content: '\2716';
  font-size: 12px;
  color: white;
}
.upload__file-close:after {
  content: '\2716';
  font-size: 12px;
  color: white;
}
.link-text{
  text-decoration: underline solid 1px !important;
}
</style>